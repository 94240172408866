import { FlatfileListener } from '@flatfile/listener';
import api from '@flatfile/api';
import { bulkRecordHook, recordHook } from '@flatfile/plugin-record-hook';
// // @ts-ignore
// import { externalConstraint } from '@flatfile/plugin-constraints';
import { createFlatfileNew, getAllBidFiles, getAllGroups, updateBiddingState } from  "../../../../redux/actions/index";
import { validateMileageField, validateZipCodeFields, validateCityStateFields, conditionallyRequiredWithoutAll, validateRegexMatches, formatNumberFieldsTwoDecimals, POSITIVE_NUMBER_WITH_UP_TO_TWO_DECIMALS, NONNEGATIVE_DOLLAR_VALUE, POSITIVE_NUMERIC_CHARACTER_ONLY } from '../utils';


export const createListener = (dispatch: any, navigate: any, flags: any, defaultRateType: any) => {
    const listener = FlatfileListener.create((listener) => {
        listener.on('**', async (event) => {
            console.log(`Received event: ${event.topic}`);
        });

        // Required Validations for flexibleLocationFlatfileFlag
        listener.use(
            recordHook(
                'bid_files',
                (r) => {
                    if(flags.flexibleLocationFlatfileFlag){
                        conditionallyRequiredWithoutAll('origin_zip', ['origin_city_state'], r);
                        conditionallyRequiredWithoutAll('origin_city_state', ['origin_zip'], r);
                        conditionallyRequiredWithoutAll('destination_zip', ['destination_city_state'], r);
                        conditionallyRequiredWithoutAll('destination_city_state', ['destination_zip'], r);
                        // See workbook.ts // https://optimaldynamics.atlassian.net/browse/ODPT-4078 - hide these fields
                        // conditionallyRequiredWithoutAll('origin_city', ['origin_zip', 'origin_city_state_zip', 'origin_city_state'], r);
                        // conditionallyRequiredWithoutAll('origin_city_state_zip', ['origin_zip', 'origin_city_state'], r);
                        // conditionallyRequiredWithoutAll('destination_city', ['destination_zip', 'destination_city_state_zip', 'destination_city_state'], r);
                        // conditionallyRequiredWithoutAll('destination_city_state_zip', ['destination_zip', 'destination_city_state'], r);
                    }
                    return r;
                }
            )
        )
        
        // REGEX Validations
        listener.use(
            recordHook(
                'bid_files',
                (r) => {
                    formatNumberFieldsTwoDecimals(r);
                    validateRegexMatches('load_volume', /^[1-9]d*|1$/, 'Please enter a value greater or equal to 1.', r);
                    validateRegexMatches('rate_per_mile', /^(?!-)(?!0$)(?!0\.0$)(?!0\.00$)([0-9]+(\.[0-9]{1,2})?)$/, 'Please enter a positive number with up to two decimals. Negative values and 0 are not allowed.', r);
                    validateRegexMatches('mileage', POSITIVE_NUMBER_WITH_UP_TO_TWO_DECIMALS, 'Please enter a valid positive number with up to two decimals.', r);
                    validateRegexMatches('other_revenue', NONNEGATIVE_DOLLAR_VALUE, 'Please enter a valid positive number with up to two decimal places.', r);
                    validateRegexMatches('other_revenue_per_mile', NONNEGATIVE_DOLLAR_VALUE, 'Please enter a valid positive number with up to two decimal places.', r);
                    if(flags.salesRanking) {
                        const salesRankingVal = r.get('sales_ranking');
                        if(!salesRankingVal) r.addError('sales_ranking', 'Sales Ranking is required')
                        else validateRegexMatches('sales_ranking', POSITIVE_NUMERIC_CHARACTER_ONLY, 'Please enter a numerical whole integer value.', r);
                    }

                    return r;
                }
            )
        )

        // Clifford API Validations
        listener.use(
            bulkRecordHook(
              'bid_files',
              async (records) => {
                if (flags.flexibleLocationFlatfileFlag){
                    await validateZipCodeFields('origin_zip', records, true);
                    await validateZipCodeFields('destination_zip', records, true);
                    await validateCityStateFields('origin_city_state', records);
                    await validateCityStateFields('destination_city_state', records);
                    await validateMileageField(records);
                } else {
                    await validateZipCodeFields('origin_zip', records, flags.zipCodeCliffordValidation);
                    await validateZipCodeFields('destination_zip', records, flags.zipCodeCliffordValidation);
                    await validateMileageField(records);
                }
                return records;
              },
              {
                chunkSize: 100,
                parallel: 2,
              }
            )
        );          

        // Set Preferred Rate Type from upload modal
        listener.use(
            bulkRecordHook(
                'bid_files',
                (records) => {
                    const formattedRecords = records.map((r) => {
                        if (flags.flatRates && !r.get('preferred_rate_type')) r.set('preferred_rate_type', defaultRateType ?? 'rate_per_mile')
                        return r;
                    });
                    return formattedRecords;
                },
                { chunkSize: 100, parallel: 2 }
            )
        );

        listener.filter({ job: 'workbook:submitActionFg' }, (configure) => {
            configure.on('job:ready', async (event) => {
                const { context: { spaceId, jobId, workbookId } } = event;
                try {
                    await api.jobs.ack(jobId, {
                        info: 'Getting started.',
                        progress: 10
                    });

                    const space = await api.spaces.get(spaceId);
                    const flatfileSubmitData = space.data.metadata.flatfileSubmitData;
                    
                    const { data: sheets } = await api.sheets.list({ workbookId });
                    const res = await api.records.get(sheets[0].id);
                    const records = res.data.records;
                    const formattedRecords = records.map((record) => {
                        const values = record.values;
                        const bidLane = Object.keys(values).reduce((acc: any, key) => {
                          acc[key] = values[key]?.value || ""; 
                          return acc;
                        }, {});
                        return bidLane;
                      });

                    const flatfileCreated = await createFlatfileNew(flatfileSubmitData.currConfigId, {bid_lanes: formattedRecords});
                    if (flatfileCreated) {
                        if (flags.odpt4185BidConfigUpdate) {
                            const newlyCreatedBidConfigId = flatfileCreated?.data?.result[0]?.bid_config;
                            navigate(`/bidding/${newlyCreatedBidConfigId}`);
                        }

                        dispatch(updateBiddingState({ currUploadingBidConfigId: '' }));
                        dispatch(getAllGroups());
                        dispatch(getAllBidFiles());
                        dispatch(
                            updateBiddingState({
                                defaultRateType: null
                            })
                        ); 
                        await api.jobs.complete(jobId, {
                            outcome: {
                                acknowledge: true,
                                message: 'Bid file successfully uploaded!',
                                next: {
                                    type: 'wait'
                                }
                            }
                        });
                    } else {
                        await api.jobs.fail(jobId, {
                            outcome: {
                                acknowledge: true,
                                message: 'Error uploading bid file',
                                next: {
                                    type: 'retry',
                                    label: 'Try again'
                                }
                            }
                        });
                    }

                    
                } catch (error: any) {
                    console.error('Error:', error.stack);

                    await api.jobs.fail(jobId, {
                        outcome: {
                            message: 'This job encountered an error.'
                        }
                    });
                }
            });
        });
    });
    return listener;
}
